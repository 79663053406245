import styled from 'styled-components';
import { MOBILE_460, TABLET_992 } from '../../styles/globals/sizes';

interface DropdownContentProps {
    isOpen: boolean;
}

export const MainContainer = styled.div`
    width:100%;
    padding-inline:15%;
    @media (max-width: ${TABLET_992}px) {
        padding-inline:5%;
    }
    @media (max-width: ${MOBILE_460}px) {
        padding-inline:5%;
    }
    `;

export const BorderContainer = styled.div`
    width:100%;
    border: 2px solid #EC4747;
    border-radius: 20px;
    overflow: hidden;
    margin-block:30px

`;

export const Title = styled.div<DropdownContentProps> `
    text-align: left;
    font-size: 24px;
    font-family: Roboto;
    color: #EC4747;
    padding-bottom: ${({isOpen}) => (isOpen ? '0' : '10px')};

    @media (max-width: ${TABLET_992}px) {
        width:80%;
    }
    @media (max-width: ${MOBILE_460}px) {
        font-size: 18px;
        width:80%;
    }
`
export const Content = styled.div `
    text-align: left;
    font-size: 16px;
    font-family: Roboto;
    color: #404040;
    padding-bottom:15px;
     @media (max-width: ${MOBILE_460}px) {
        width:90%;
        font-size: 16px;
    }
`
export const DropdownHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 10px 20px 5px 20px ;
    cursor: pointer;
`

export const ArrowStyles = styled.img<DropdownContentProps>`
    transition: 0.1s linear;
    transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`


export const DropdownContent = styled.div<DropdownContentProps>`
    height: ${({isOpen}) => (isOpen ? 'auto' : '0')};
    overflow: hidden;
    margin-right: 60px;
    padding: ${({isOpen}) => (isOpen ? '0px 20px 10px 20px' : '0px')};
    transition: max-height 0.1s ease, opacity 0.1s ease;
     @media (max-width: ${MOBILE_460}px) {
        margin-right: 0;

    }
`;
