import styled from "styled-components";
import {
  DESKTOP_1200,
  MOBILE_460,
  TABLET_600,
  TABLET_992,
} from "../globals/sizes";

export const MainContainer = styled.div`
  margin: 90px auto 80px;
  @media (max-width: ${TABLET_992}px) {
    margin: 90px 0 90px;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin: 90px 0 10px;
  }
`;

export const BannerContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: white;
  object-fit: cover;
`;

export const BannerImageWeb = styled.img`
  width: 100%;
  object-fit: cover;
  margin-right: 0;
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const BannerImageTablet = styled.img`
  display: none;
  @media (max-width: ${TABLET_992}px) {
    display: block;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: none;
  }
`;

export const BannerImageMobile = styled.img`
  display: none;
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: block;
  }
`;

export const InternalWrapper = styled.div`
  width: 70%;
  max-width: 1500px;
  position: absolute;
  top: 15vh;
  @media (max-width: ${DESKTOP_1200}px) {
    width: 100%;
    top: 5vh;
  }
  @media (max-width: ${TABLET_992}px) {
    position: relative;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin-top: 20px;
    top: 0;
  }
`;

export const HeroTextDiv = styled.div`
    padding: 10vh 70px;
    box-sizing border-box;
    margin: auto;
    @media (max-width: ${TABLET_992}px) {
        width: 100%;
        padding: 0 70px 0 70px;
    }
    @media (max-width: ${MOBILE_460}px) {
        width: 100%;
        padding: 0 0;
        margin-right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
    }
`;

export const Title = styled.h1`
  width: 12em;
  margin-bottom: 20px;
  text-align: left;
  font-size: 40px;
  font-family: Roboto;
  font-weight: 500;
  text-transform: uppercase;
  color: #e0281a;
  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin: 14px 0;
    font: 35px Roboto;
    text-align: center;
  }

  @media (max-width: ${MOBILE_460}px) {
    width: 100%;
    padding: 0px 16px;
    margin-right: 0px;
    text-align: center;
    font-size: 30px;
  }
`;

export const Description = styled.p`
  width: 449px;
  margin: 10px 0px 20px;
  text-align: left;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 400;
  color: #404040;
  line-height: 30px;
  @media (max-width: ${DESKTOP_1200}px) {
    width: 41%;
  }
  @media (max-width: ${TABLET_992}px) {
    height: 11vh;
    margin: 14px 0;
    font: 20px Roboto;
    width: 100%;
    text-align: center;
  }
  @media (max-width: ${TABLET_600}px) {
    height: 15vh;
    margin-bottom: 30px;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 100%;
    padding: 0px 16px;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
  }
`;
