import React, { useState } from "react";
import {
  ArrowStyles,
  BorderContainer,
  Content,
  DropdownContent,
  DropdownHeader,
  MainContainer,
  Title,
} from "./styles";
import ArrowDown from "../../images/arrow-up.svg";
import { PATHS } from "../../config/urls";

const FaqDropdown = () => {
  const [isOpen, setIsOpen] = useState(null);
  const faqItems = [
    {
      title: "What is Life Home Care?",
      content:
        "Life Home Care is a home care agency that offers personalized services for seniors and individuals needing assistance at home. Our goal is to provide high-quality care and improve the quality of life for our clients.",
    },
    {
      title: "What services does Life Home Care offer?",
      content:
        "We offer a variety of services including personal care, help with household tasks, companionship, medication reminders, and specialized care for conditions such as Alzheimer's and dementia.",
    },
    {
      title: "How are caregivers selected?",
      content:
        "All our caregivers undergo a rigorous selection process that includes background checks, detailed interviews, and thorough training to ensure they meet our high standards of quality and safety.",
    },
    {
      title: "Are services available 24/7?",
      content:
        "Yes, we offer 24/7 care services, including nights, weekends, and holidays, to ensure that someone is always available to assist.",
    },
    {
      title: "How is the care plan determined?",
      content:
        "We work closely with families and healthcare professionals to assess the client's needs and develop a personalized care plan tailored to their specific requirements.",
    },
    {
      title: "Can we change the caregiver if we are not satisfied?",
      content:
        "Yes, your satisfaction is our priority. If for any reason you are not satisfied with your caregiver, we will work to find a suitable solution and assign a different caregiver if necessary.",
    },
    {
      title: "What safety measures are implemented during care?",
      content:
        "We implement strict safety measures, including the use of personal protective equipment (PPE), adherence to hygiene protocols, and ongoing training in safe practices to protect both our clients and caregivers.",
    },
    {
      title: "What are the costs associated with Life Home Care services?",
      content:
        "We are a private pay home care agency, costs vary depending on the type and amount of services required. We offer free consultations to assess your needs and provide a clear cost estimate.",
    },
    {
      title: "Do you accept insurance or Medicaid?",
      content:
        "We work with long term care insurance agencies. However, we do not participate with Medicare or Medicaid programs.",
    },
    {
      title: "What happens if medical emergency care is needed?",
      content:
        "In the event of a medical emergency, our caregivers are trained to provide first aid and call emergency services. We will immediately communicate with designated emergency contacts.",
    },
    {
      title: "Do caregivers receive ongoing training?",
      content:
        "Yes, all our caregivers receive ongoing training to stay updated with best practices and care protocols, including specialized care for various health conditions. Because we are licensed and accredited our aides adhere to completing 24 education in-services within a 2 year period.",
    },
    {
      title: "Are Life Home Care services available in my area?",
      content: (
        <span>
          We offer services in various locations.{" "}
          <a
            style={{ fontSize: 16, fontFamily: "Roboto", color: "red" }}
            href={PATHS.LOCATIONS}
          >
            Click here for our service coverage
          </a>
          .
        </span>
      ),
    },
    {
      title: "Can I change the schedule of services once contracted?",
      content:
        "Yes, we understand that needs can change. We work with you to adjust the schedule and services as needed.",
    },
    {
      title: "How is the quality of care monitored?",
      content:
        "We conduct regular supervision visits and continuously seek feedback from clients to ensure our services meet the highest standards of quality.",
    },
    {
      title: "What types of household tasks can caregivers perform?",
      content:
        "Our caregivers can assist with various light household tasks such as tidying up, dusting, meal preparation, laundry, and errands.",
    },
    {
      title: "How can I communicate with my caregiver or care coordinator?",
      content:
        "We provide multiple communication methods, including phone, email, and a customer service hotline, to ensure you can always reach us easily.",
    },
    {
      title: "What is the process for starting services with Life Home Care?",
      content:
        "The process begins with a complimentary consultation to assess your needs and personality. We then develop a personalized care plan and select a suitable caregiver for you.",
    },
    {
      title: "What if I need temporary or short-term care?",
      content:
        "We offer both long-term and short-term care services. Whether you need temporary care during recovery or ongoing care, we can tailor our services to meet your needs.",
    },
    {
      title: "Can I get references or testimonials from other clients?",
      content:
        "Yes, we are proud of the relationships we have built with our clients and can provide references and testimonials to help you feel confident in choosing us.",
    },
    {
      title: "How do you ensure client confidentiality and privacy?",
      content:
        "We take client confidentiality and privacy very seriously. We comply with all data protection regulations and ensure all personal information is handled with the utmost care and discretion.",
    },
  ];

  const toggleDropdown = (index) => {
    setIsOpen(isOpen === index ? null : index);
  };

  return (
    <MainContainer>
      {faqItems.map((item, index) => (
        <BorderContainer key={index}>
          <DropdownHeader onClick={() => toggleDropdown(index)}>
            <Title>{item.title}</Title>
            <ArrowStyles
              src={ArrowDown}
              alt="arrow down"
              isOpen={isOpen === index}
            />
          </DropdownHeader>
          <DropdownContent isOpen={isOpen === index}>
            <Content>{item.content}</Content>
          </DropdownContent>
        </BorderContainer>
      ))}
    </MainContainer>
  );
};

export default FaqDropdown;
